//import { devConsoleLog } from "../utility/functions/functions";
//import request from "./request";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

let environment, urlStart;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  environment = "dev-lotto1";
  urlStart = "https://r0cyqqadyg.execute-api.eu-west-1.amazonaws.com/";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
  environment = "prod";
  urlStart = "https://hey5ehb018.execute-api.eu-west-1.amazonaws.com/";
}

/**
 * @description User Log in
 * @export
 * @param {*} username
 * @param {*} password
 * @return {*}
 */
export async function apiUserLogin(username, password) {
  let user = {};
  try {
    user = await Auth.signIn(username, password);
    global.cognitoUserObject = user;
  } catch (error) {
    //console.log("error signing in", error);
  }
  return user;
}
/**
 * @description User SMS MFA
 * @param {*} code
 * @returns
 */
export async function apiUserConfirm(code) {
  let loggedUser = {};
  try {
    loggedUser = await Auth.confirmSignIn(
      global.cognitoUserObject, // Return object from Auth.signIn()
      code, // Confirmation code
      "SMS_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );
  } catch (error) {}
  return loggedUser;
}

export async function apiCurrentSession() {
  //console.log("API | apiCurrentSession()");
  let session = {};
  try {
    session = await Auth.currentSession();
  } catch (error) {
    //console.log("API | apiCurrentSession().error:", error);
  }
  return session;
}

export async function apiUserLogout() {
  //console.log("API | apiUserLogout()");
  try {
    await Auth.signOut();
  } catch (error) {
    //console.log("API | apiUserLogout().error:", error);
  }
}

/**
 * @description Gets contract list for the entity
 * @export
 * @param {*} userId
 * @param {*} entityId
 * @return {*}
 */
export async function apiGetContracts(userId, entityId, token) {
  //console.log("API | apiGetContracts().userId: " + userId);
  //console.log("API | apiGetContracts().entityId: " + entityId);
  //console.log("API | apiGetContracts().token: " + token);
  let url =
    //urlStart+environment+"/executivecontracts/" +
    urlStart + environment + "/contractnames/" + userId + "/" + entityId;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
  });
}

/**
 * @description Gets contract details
 * @export
 * @param {*} userId
 * @param {*} entityId
 * @return {*}
 */
export async function apiGetContractDetails(contractId, token) {
  //console.log("API | apiGetContractDetails().contractId: " + contractId);
  //console.log("API | apiGetContractDetails().token: " + token);
  let url = urlStart + environment + "/executivecontracts/" + contractId;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    // toast.error("Errore nel prendere i dati del contratto");
  });
}

/**
 * @description Gets list of news
 * @export
 * @param {*} last
 * @return {*}
 */
export async function apiGetNews(last) {
  //console.log("API | apiGetNews()");
  //console.log("API | apiGetNews().last:", last);
  let url = last
    ? urlStart + environment + "/news?" + new URLSearchParams({ last })
    : urlStart + environment + "/news";

  return fetch(url, {
    method: "GET",
  }).catch((err) => {
    //console.log("error:", err);
    // toast.error("Errore nel prendere le News");
  });
}

/**
 * @description Get Single News
 * @export
 * @param {*} idNews
 * @return {*}
 */
export async function apiGetSingleNews(idNews) {
  //console.log("API | apiGetSingleNews()");
  //console.log("API | apiGetSingleNews().idNews:", idNews);
  let url = urlStart + environment + "/new/" + idNews;

  return fetch(url, {
    method: "GET",
  }).catch((err) => {
    //console.log("error:", err);
    // toast.error("Errore nel prendere i dati della News");
  });
}

export async function apiGetAQNumbers() {
  //console.log("API | apiGetAQNumbers()");
  return fetch(urlStart + environment + "/numeriaccordoquadro", {
    method: "GET",
  }).catch((err) => {
    //console.log("error:", err);
    // toast.error("Errore nel prendere i dati dell' AQ");
  });
}

/**
 * 
 * @returns 
 
export async function apiGetHomePageInitiatives() {
  //console.log("API | apiGetHomePageInitiatives()");
  return fetch(
    urlStart+environment+"/homeiniziatives",
    { method: "GET" }
  );
}
*/

/**
 * @description Gets Private Common Documents
 * @export
 * @return {*}
 */
export async function apiGetPrivateCommonDocuments(doc, token) {
  //console.log("API | apiGetPrivateCommonDocuments().token: " + token);
  let url = urlStart + environment + "/documents/" + doc;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    // toast.error("Errore nel prendere i documenti");
  });
}

/**
 * @description Gets Public Documents
 * @export
 * @return {*}
 */
export async function apiGetPublicDocuments() {
  let url = urlStart + environment + "/publicDocuments/documents";
  return fetch(url, {
    method: "GET",
  }).catch((err) => {
    //console.log("error:", err);
    // toast.error("Errore nel prendere i documenti");
  });
}

/**
 * @description Gets Contract Documents
 * @export
 * @return {*}
 */
export async function apiGetContractDocuments(id_contratto, id_user, token) {
  let url =
    urlStart + environment + "/metadata/" + id_user + "/" + id_contratto;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    // toast.error("Non sono stati trovati documenti relativi a questo contratto");
  });
}

/**
 * @description Gets Contract Documents
 * @export
 * @return {*}
 */
export async function apiGetUserEntity(id_user, token) {
  let url = urlStart + environment + "/userentes/" + id_user;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("Errore nella selezione Ente");
  });
}

export async function apiGetSection(token) {
  //console.log("API | apiGetAQNumbers()");
  return fetch(urlStart + environment + "/getsections", {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("Errore nell'ottenere le sezioni");
  });
}

export async function apiInsertDocuments(data, token) {
  return fetch(urlStart + environment + "/upload", {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("Errore durante l'inserimento dei dati");
  });
}

/**
 * @description Gets user type
 * @export
 * @return {*}
 */
export async function apiGetAdmin(id_user, token) {
  let url = urlStart + environment + "/getgrade/" + id_user;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("Non puoi accedere");
  });
}
export async function apiGetFilters(token) {
  let url = urlStart + environment + "/getfilters";
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("No filters found");
  });
}

export async function apiMonitoringDetails(data, token) {
  let url = urlStart + environment + "/monitoringdetails";
  console.log("this is what I send", JSON.stringify(data));
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("No details found");
  });
}

export async function apiSLA(data, token) {
  let url = urlStart + environment + "/servicelevelagreement";
  console.log("this is what I send", JSON.stringify(data));
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("No details found");
  });
}

export async function apiDigIndexes(data, token) {
  let url = urlStart + environment + "/digitalizationIndexes";
  console.log("this is what I send", JSON.stringify(data));
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("No details found");
  });
}

export async function apiGetTipologiaBudget(id_user, token) {
  let url = urlStart + environment + "/tipologiabudget/" + id_user;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("Non puoi accedere");
  });
}

export async function apiGetEcosistemBudget(id_user, token) {
  let url = urlStart + environment + "/ecosistembudget/" + id_user;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).catch((err) => {
    //console.log("error:", err);
    toast.error("Non puoi accedere");
  });
}
