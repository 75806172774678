/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
 
const awsmobile = {
  aws_project_region: "eu-west-1",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_7ZtdfvLk4",
  aws_user_pools_web_client_id: "5j06s5gpdl5053ncnkd7424b5a",
  oauth: {
    domain: "cloud-enabling-test.auth.eu-west-1.amazoncognito.com",
    scope: ["aws.cognito.signin.user.admin"],
    redirectSignIn:
      "https://cloud-enabling-test.auth.eu-west-1.amazoncognito.com",
    redirectSignOut:
      "https://cloud-enabling-test.auth.eu-west-1.amazoncognito.com",
    responseType: "token",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_content_delivery_bucket: "cloud-enabling-fe-dev-lotto1-dev",
  aws_content_delivery_bucket_region: "eu-west-1",
  aws_content_delivery_url: "https://d1sytb4mv6zk27.cloudfront.net",
};

export default awsmobile;