import { Card, CardReadMore } from "design-react-kit";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardBody } from "reactstrap";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import { getPrivateCommonDocuments } from "../../redux/actions/documents";
import { InfoCard } from "./InfoCard";

export default function InfoPage() {
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents);
  const user = useSelector((state) => state.user.user);
  let history = useHistory();

  useEffect(() => {
    if (user === undefined || user === null) history.push("/login");
    else {
      dispatch(getPrivateCommonDocuments("Guida"));
      dispatch(getPrivateCommonDocuments("Models"));
      dispatch(getPrivateCommonDocuments("Solution"));
    }
  }, []);

  return (
    <>
      <div className="container">
        <BreadcrumbConsip
          pages={[{ label: "Area Informativa", url: "/info" }]}
        />
      </div>
      <div className="info-gradient pb-5">
        <div className="container">
          <div className="text-left pb-5">
            <h3>Guide ai servizi</h3>
          </div>
        </div>
        <div className="container px-3">
          <div className="row">
            <div className="col-12 col-md-4 ">
              <InfoCard
                cardClass="card-bg rounded"
                title={"Strategia e Governance"}
                caption="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
                icon={
                  process.env.PUBLIC_URL +
                  "/assets/icons/info-soluzioni-migliorative.svg"
                }
                // url={
                //   documents?.privateSolution !== undefined
                //     ? documents.privateSolution
                //     : ""
                // }
                url={
                  "https://consip-public-files.s3.eu-west-1.amazonaws.com/cloudenabling/StategiaGovernance.pdf"
                }
              />
            </div>
            <div className="col-12 col-md-4 ">
              <InfoCard
                cardClass="card-bg rounded"
                title={"Modelli Operativi"}
                caption="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
                icon={
                  process.env.PUBLIC_URL +
                  "/assets/icons/info-modelli-operativi.svg"
                }
                // url={
                //   documents?.privateModels !== undefined
                //     ? documents.privateModels
                //     : ""
                // }
                url={
                  "https://consip-public-files.s3.eu-west-1.amazonaws.com/cloudenabling/ModelliOperativi.pdf"
                }
              />
            </div>
            <div className="col-12 col-md-4 ">
              <InfoCard
                cardClass="card-bg rounded"
                title="Guida alla stima"
                caption="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
                icon={
                  process.env.PUBLIC_URL +
                  "/assets/icons/info-guida-alla-stima.svg"
                }
                // url={
                //   documents?.privateGuida !== undefined
                //     ? documents.privateGuida
                //     : ""
                // }
                url={
                  "https://consip-public-files.s3.eu-west-1.amazonaws.com/cloudenabling/AQ_ServiziCloudEnablingGuidaAllaStima.xlsx"
                }
              />
            </div>
          </div>
          <div className="mt-5 text-center bg-white container">
            <h5 className="py-4" style={{ fontWeight: "bold" }}>
              Documentazione
            </h5>
            <div className="row">
              <div className="col-4">
                <img
                  className="py-4"
                  src={
                    process.env.PUBLIC_URL + "/assets/icons/info-normativa.svg"
                  }
                  alt="icon"
                />
                <h6>Normativa</h6>
              </div>
              <div className="col-4">
                <img
                  className="py-4"
                  src={
                    process.env.PUBLIC_URL + "/assets/icons/info-tecnica.svg"
                  }
                  alt="icon"
                />
                <h6>Tecnica</h6>
              </div>
              <div className="col-4">
                <img
                  className="py-4"
                  src={
                    process.env.PUBLIC_URL + "/assets/icons/info-operativa.svg"
                  }
                  alt="icon"
                />
                <h6>Operativa</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Card noWrapper={false} tag="div">
                  <CardBody tag="div">
                    <CardReadMore
                      iconName="it-arrow-right"
                      tag="a"
                      text="Vedi i dettagli"
                      onClick={() => history.push("/documents")}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
