import React from "react";
import { AccordionConsip } from "../../components/AccordionConsip";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

const data = [
  {
    id: 0,
    title: "ASSESSMENT (S1)",
    bodyStart:
      "Servizio di assessment del percorso di abilitazione al cloud che prevede l’esecuzione di alcune attività specifiche tra cui l’analisi della situazione in essere (AS-IS), la realizzazione di una Lista degli Applicativi, comprensiva della mappatura degli applicativi e la loro prioritizzazione.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
  {
    id: 1,
    title: "STRATEGIA DI MIGRAZIONE (S2)",
    bodyStart:
      "Servizio di supporto nell’identificazione delle strategie di migrazione per i singoli applicativi, o aree applicative o sistema informativo. Include, come output, le singole schede degli applicativi ed uno specifico documento di riepilogo delle strategie attuabili per ciascun applicativo.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
  {
    id: 2,
    title: "STUDIO DI FATTIBILITA’ (S3)",
    bodyStart:
      "Servizio di supporto nella determinazione di uno studio di fattibilità in merito alla migrazione del singolo applicativo ovvero dell’area o sistema informativo oggetto di migrazione, che comprenda al suo interno l’analisi costi-benefici e la valutazione delle competenze necessarie alla migrazione ed in possesso dell’Amministrazione o quelle potenzialmente acquisibili tramite altri contratti di fornitura, prevedendo anche eventuali proposte per la tematica del lock – in.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
  {
    id: 3,
    title: "PMO (S4)",
    bodyStart:
      "Servizi di Project Management aventi lo scopo di supportare organizzazione, pianificazione, controllo e coordinamento generale delle attività di tipo progettuale e tecnico dell’Amministrazione, con particolare riferimento alle attività di Check dei risultati, orientato alla fase finale del processo di migrazione in cloud, ed alle attività Project Management, relativo all’intera gestione del processo di migrazione.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
];

export default function ServicesPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Servizi", url: "/service" }]} />
      </div>
      <div className="pb-5">
        <div className="container" style={{ paddingBottom: "20vh" }}>
          <div className="text-left pb-3">
            <h3>Servizi</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              I servizi oggetto dell’Accordo Quadro sono:
            </div>
            <div className="col-12 text-left">
              <AccordionConsip data={data}></AccordionConsip>
            </div>
            <div className="col-12 text-left py-3">
              <p>
                Per maggiori dettagli si veda il capitolo 5 del{" "}
                <a
                  href={
                    "https://consip-public-files.s3.eu-west-1.amazonaws.com/cloudenabling/All.+18A+-+ID+2652+-+Gara+Cloud+Enabling+-+Capitolato+Tecnico+Speciale+Servizi+di+Supporto+Lotti+1-5.pdf"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Capitolato Tecnico Speciale Lotti 1-5
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
