import React from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

export default function ServicesPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Chi Siamo", url: "/chi-siamo" }]} />
      </div>
      <div className="pb-5">
        <div className="container">
          <div className="text-left pb-3">
            <h3>Chi Siamo</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              <img
                className="img my-2"
                src={"./assets/icons/deloitteLogo.png"}
                height={30}
                alt="deloitte"
              />
              <p className="text-justify">
                <b>Deloitte Consulting S.r.l. S.B.</b> è la società che offre servizi di consulenza
                strategica, operativa e direzionale nell'ambito del network Deloitte,
                leader nel settore dei servizi professionali rivolti sia a clienti del mondo
                pubblico che privato e operante in tutte le principali Industry di mercato.
                Il <b>Network Deloitte</b> è presente in oltre 150 paesi, con un fatturato globale
                (al 2022) di ca. 59 miliardi di dollari e può contare su un capitale intellettuale
                notevole, sviluppato da oltre <b>415.000 professionisti altamente specializzati</b> in
                tutto il mondo. Il Consulting di Deloitte in Italia fornisce servizi ai principali
                Gruppi finanziari ed industriali e alle principali PA Centrali (es. MEF, MLPS, ANAS, SOGEI),
                ed è particolarmente nota sul mercato per la sua presenza a supporto delle
                Pubbliche Amministrazioni Locali (Regioni e Comuni in primis). In particolare,
                <b>presso la PA, Deloitte è advisor sulle tematiche di ICT Strategy, Innovazione e
                  Digital Transformation</b>, grazie alle competenze maturate e all’approccio specialistico
                adottato dai professionisti appartenenti alla <b>Industry G&PS – Government & Public Services.</b>
                <p><b> Deloitte è riconosciuta dalle principali società di analisi del mercato come Leader
                  globale nei servizi Cloud:</b></p>
              </p>
              <ul>
                <li><b>Prima società al mondo</b> sui servizi <b>Cloud</b>, secondo Gartner e IDC MarketScape nel 2022;</li>
                <li>Leader a livello globale di servizi di Data Center Outsourcing e Hybrid Infrastructure Manager Services nel 2022 secondo Gartner; </li>
                <li>Leader a livello globale di servizi di consulenza e integrazione Hybrid IT, secondo IDC MarketScape nel 2023; </li>
                <li>Leader a livello globale per i servizi di sicurezza Cloud, secondo IDC MarketScape nel 2022</li>
                <li>Leader a livello globale nei servizi di modernizzazione e migrazione delle applicazioni, secondo Forrester nel 2021.</li>
              </ul>
              <p>
                Gli oltre <b>30.000 professionisti del Cloud Network</b> costituiscono la linea di servizio
                Deloitte dedicata a <b>supportare i Clienti in tutte le fasi della Cloud Journey:</b> dalla definizione
                della strategia alla migrazione/replatforming in Cloud, customizzazione e manutenzione post
                Migration delle applicazioni in Cloud. Nel corso degli ultimi 5 anni, Deloitte ha supportato
                centinaia di organizzazioni nella trasformazione Cloud, <b>migrando oltre 400.000 server</b> e sviluppando
                migliaia di applicazioni Cloud native. La qualità e l’efficienza dei servizi è garantita da una rete
                di Delivery Center che operano in modo coordinato nelle principali geografie. Soltanto in Italia,
                la practice Cloud Deloitte conta <b>circa 800 persone</b>, con una copertura estesa di tutte le Industry
                e in particolare nel settore Financial Services, in ambito Pubblica Amministrazione ed Energy & Utilities.
              </p>
              <p>La leadership di Deloitte in ambito Cloud è riconosciuta dai principali <b>Cloud Service Provider</b>,
                tramite la certificazione di competenze professionali su tutti i principali servizi Cloud. <b>I professionisti
                  di Deloitte hanno ottenuto oltre 16.500 certificazioni AWS, oltre 5.500 certificazioni Google Cloud e oltre
                  18.000 certificazioni Microsoft Azure di ogni livello.</b> In aggiunta, Deloitte è partner dei principali vendor di
                soluzioni tecnologiche e Applicative Cloud, consentendo al proprio network di professionisti di accedere a programmi
                di training avanzato e supporto specialistico dedicato su tutte le tecnologie Cloud di riferimento.</p>
              <br></br>
              <img
                // className="img img-fluid"
                src={"./assets/icons/EYLogo.png"}
                height={50}
                alt="EYLogo"
              />
              <p className="text-justify">
                <b>EY Advisory S.p.A</b>. (di seguito <b>EY</b>) è parte del Gruppo EY, uno dei
                leader mondiali nei servizi professionali di consulenza con un
                fatturato mondiale di 45,4 Mld USD e più di 300 mila professionisti
                in oltre 150 Paesi. In Italia il network è presente in 17 città con
                più di 6.000 risorse e ha designato la Pubblica Amministrazione quale
                segmento prioritario, attraverso la creazione del centro di eccellenza
                dedicato “Government & Public Sector – GPS”. Negli ultimi 20 anni EY
                ha maturato una profonda esperienza nel settore pubblico (Ministeri,
                Agenzie fiscali, Enti Pubblici Economici, Società a partecipazione
                Pubblica, Authority, Amministrazioni Locali) per il quale ha sviluppato
                metodologie e approcci specifici, consolidando la conoscenza delle
                dinamiche di riferimento, delle principali criticità gestionali e di
                contesto, delle peculiarità normative, amministrative e tecnologiche.
                Per supportare la PA nel processo di trasformazione digitale e
                innovazione dei servizi ai cittadini e alle imprese, anche attraverso
                l’utilizzo delle moderne tecnologie, EY opera in cinque aree di
                competenza: Technology Transformation, Technology Solution Delivery,
                Digital Engineering, Data & Analytics, Cybersecurity & Digital
                Protection.
              </p>
              <p> EY dispone di un <b>Cloud Center of Excellence (CCoE)</b> con oltre
                <b> 17.000 professionisti</b> (di cui oltre 300 in Italia) all’interno del proprio
                network in grado di fornire servizi Cloude di realizzare tutte le
                fasi che caratterizzano la Cloud Transformation. Quest’ultimo fornisce
                l’approccio per supportare i clienti ad accelerare il loro percorso di
                migrazione al Cloud, tramite una strategia globale di trasformazione che
                si sviluppa su due principi: <b>People & Process, Technology.</b> Nel corso
                degli anni sono state consolidate numerose <b>partnership con i maggiori
                  player tecnologici internazionali</b>, al fine di offrire ai propri clienti
                soluzioni sempre più innovative e strumenti in grado di facilitare il
                passaggio al Cloud. Ciò è stato favorito anche dalla specializzazione e
                dal continuo aggiornamento dei professionisti del network su tali temi,
                reso possibile da un programma internazionale di certificazione (oltre 13.000)
                mirato ad acquisire competenze specifiche sulle più importanti piattaforme
                di abilitazione al Cloud: <b>AWS, Microsoft, IBM, Google, Oracle</b>.
                A livello globale, EY coniuga la sua leadership ed esperienza in ambito Cloud alle più ampie
                capacità di trasformazione aziendale e tecnologica, supportando e sostenendo i propri Clienti
                nei processi di <b>Cloud Adoption e Cloud Optimization</b> attraverso approcci customizzati che coprono
                tutti gli aspetti strategici ed operativi, con forti nei contesti e settori di riferimento.
                A tal proposito, EY presenta i seguenti riconoscimenti:
              </p>
              <ul>
                <li><b>Global Leader</b> come fornitore di Servizi Professionali Cloud secondo l’IDC MarketScape (2022); </li>
                <li><b>Oltre 10 Microsoft Partner of the Year Awards</b> ricevuti nel 2022, tra cui il Microsoft Global Advisory of the Year (ricevuto per tre anni consecutivi); </li>
                <li><b>Vincitrice</b> del <b>DevOps Business Value di DASA</b> – DevOps Agile Skills Association (2022);  </li>
                <li><b>Global Leader</b> nel settore <b>Affiliate per Data & Entity</b> (2022); </li>
                <li><b>Global Leader</b> per i <b>Servizi di Implementazione Microsoft</b> da IDC (2021);</li>
                <li><b>Global Leader</b> per i <b>Servizi Applicativi Aziendali Microsoft</b> da The Forrester Wave (2021).</li>
              </ul>
              {/*<img
                className="img img-fluid"
                src={"./assets/icons/commedia-Logo.png"}
                height={10}
                alt="commediaLogo"
              />
              <p className="text-justify">
                Con significative expertise in progettazione e sviluppo di
                comunicazione e applicazione web e competenze specifiche nella
                comunicazione e nella gestione di progetti complessi Co.M.Media
                approccia le esigenze del cliente in modo strategico e
                innovativo per standard qualitativi e con una grande
                flessibilità operativa. Co.M.Media opera nel campo
                dell’E-Business e dell’Information & Communication Technology,
                sfruttando l’innovazione tecnologica per dotare soggetti
                pubblici e privati di soluzioni integrate e modulari che
                supportano i processi aziendali, la comunicazione interna ed
                esterna, la gestione delle attività.
              </p>
              <p className="text-justify">
                Co.M.Media progetta, realizza e fornisce strumenti, prodotti e
                servizi in grado di generare visibilità e sostenere le strategie
                integrate di comunicazione attraverso l’utilizzo efficace e
                produttivo della comunicazione multimediale, in linea con le
                sempre crescenti esigenze di interattività e ai nuovi paradigmi
                del web.
              </p>
                */}
            </div>
            <div className="col-12 text-left"></div>
          </div>
        </div>
      </div>
    </>
  );
}
