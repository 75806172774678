import {
  apiCurrentSession,
  apiGetContractDocuments,
  apiGetPrivateCommonDocuments,
  apiGetPublicDocuments,
  apiInsertDocuments,
} from "../../services/apiRequests";

export const getContractDetails = (contractId, userId) => {
  //console.log("ACTION | getContractDetails");

  return (dispatch) => {
    //console.log("ACTION | getContracts -> inside action");

    dispatch({ type: "SET_LOADING", payload: true });
    apiCurrentSession()
      .then((response) => {
        //console.log("CHECK SESSION | session:", response);
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiGetContractDocuments(contractId, userId, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            if (response) {
              let documents = {};
              let specialDocuments = {};
              response?.map((elem, index) => {
                if (
                  elem.sub_section_name &&
                  elem.section_name !== "Gestione Progettuale"
                ) {
                  //Section exist?
                  if (documents[elem.section_id]) {
                    //SubSection exist?
                    let check = false;
                    documents[elem.section_id].subSection.map(
                      (found, indexFound) => {
                        if (found.id === elem.id_sub_section) {
                          found.links = [
                            ...found.links,
                            { nameLink: elem.document_name, link: elem.url },
                          ];
                          check = true;
                        }
                      }
                    );
                    //add new SubSection and its name
                    if (!check) {
                      documents[elem.section_id].subSection = [
                        ...documents[elem.section_id].subSection,
                        {
                          id: elem.id_sub_section,
                          title: elem.sub_section_name,
                          links: [
                            { nameLink: elem.document_name, link: elem.url },
                          ],
                        },
                      ];
                    }
                  }
                  //add new Section, Subsection and their name
                  else {
                    documents[elem.section_id] = {
                      subSection: [
                        {
                          id: elem.id_sub_section,
                          title: elem.sub_section_name,
                          links: [
                            { nameLink: elem.document_name, link: elem.url },
                          ],
                        },
                      ],
                      section: elem.section_name,
                    };
                  }
                } else {
                  let sectionName = elem.section_name.replaceAll(" ", "");
                  switch (sectionName.toLowerCase()) {
                    case "pianodelfabbisogni":
                      if (specialDocuments.pianodelfabbisogni)
                        specialDocuments.pianodelfabbisogni = [
                          ...specialDocuments.pianodelfabbisogni,
                          {
                            title: elem.document_name,
                            url: elem.url,
                            startData: elem.date_oad,
                          },
                        ];
                      else
                        specialDocuments.pianodelfabbisogni = [
                          {
                            title: elem.document_name,
                            url: elem.url,
                            startData: elem.date_oad,
                          },
                        ];
                      break;
                    case "pianooperativo":
                      if (specialDocuments.pianooperativo)
                        specialDocuments.pianooperativo = [
                          ...specialDocuments.pianooperativo,
                          {
                            title: elem.document_name,
                            url: elem.url,
                            startData: elem.date_oad,
                          },
                        ];
                      else
                        specialDocuments.pianooperativo = [
                          {
                            title: elem.document_name,
                            url: elem.url,
                            startData: elem.date_oad,
                          },
                        ];
                      break;
                    case "contrattoesecutivo":
                      if (specialDocuments.contrattoesecutivo)
                        specialDocuments.contrattoesecutivo = [
                          ...specialDocuments.contrattoesecutivo,
                          {
                            title: elem.document_name,
                            url: elem.url,
                            startData: elem.date_oad,
                          },
                        ];
                      else
                        specialDocuments.contrattoesecutivo = [
                          {
                            title: elem.document_name,
                            url: elem.url,
                            startData: elem.date_oad,
                          },
                        ];
                      break;
                    case "gestioneprogettuale":
                      let subSectionName = elem.sub_section_name.replaceAll(
                        " ",
                        ""
                      );
                      subSectionName = subSectionName.toLowerCase();
                      if (
                        specialDocuments.gestioneprogettuale &&
                        specialDocuments.gestioneprogettuale[subSectionName]
                      ) {
                        specialDocuments.gestioneprogettuale[subSectionName] = [
                          ...specialDocuments.gestioneprogettuale[
                            subSectionName
                          ],
                          { title: elem.document_name, url: elem.url },
                        ];
                      } else {
                        if (!specialDocuments.gestioneprogettuale)
                          specialDocuments.gestioneprogettuale = {};

                        specialDocuments.gestioneprogettuale[subSectionName] = [
                          { title: elem.document_name, url: elem.url },
                        ];
                      }
                      break;
                    default: {
                    }
                  }
                }
              });

              dispatch({
                type: "SET_CONTRACT_DETAILS",
                payload: documents,
                sp: specialDocuments,
              });
              dispatch({ type: "SET_LOADING", payload: false });
            } else {
              //console.log("OK");
              dispatch({
                type: "SET_CONTRACT_DETAILS",
                payload: undefined,
                sp: undefined,
              });
              dispatch({ type: "SET_LOADING", payload: false });
            }
          });
      });
  };
};

export const getPrivateCommonDocuments = (doc) => {
  //console.log("ACTION | getPrivateCommonDocuments", doc);
  return (dispatch) => {
    if (doc !== undefined && doc !== null && doc !== "") {
      dispatch({ type: "SET_LOADING", payload: true });
      apiCurrentSession()
        .then((response) => {
          let token = response.getAccessToken().getJwtToken();
          return token;
        })
        .then((token) => {
          apiGetPrivateCommonDocuments(doc, token)
            .then((response) => {
              try {
                return response.json();
              } catch (error) {
                //console.log("ACTION | getPrivateCommonDocuments ERROR:", error);
                return { undefined };
              }
            })
            .then((responseDoc) => {
              if (responseDoc) {
                dispatch({
                  type: "SET_PRIVATE_COMMON_DOCUMENTS",
                  payload: responseDoc,
                  doc: doc,
                });
                dispatch({ type: "SET_LOADING", payload: false });
              } else {
                dispatch({
                  type: "SET_PRIVATE_COMMON_DOCUMENTS",
                  doc: "",
                });
                dispatch({ type: "SET_LOADING", payload: false });
              }
            });
        });
    } else
      dispatch({
        type: "SET_PRIVATE_COMMON_DOCUMENTS",
        doc: "",
      });
  };
};

export const insertDocuments = (values) => {
  //console.log("ACTION | insertDocuments", values);
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiInsertDocuments(values, token)
          .then((response) => {
            try {
              return response;
            } catch (error) {
              //console.log("ACTION | insertDocuments ERROR:", error);
              dispatch({ type: "SET_LOADING", payload: false });
              return { undefined };
            }
          })
          .then((responseDoc) => {
            if (responseDoc === "Success") {
              dispatch({
                type: "SET_INSERTED_DOCUMENT",
                payload: responseDoc,
              });
              dispatch({ type: "SET_LOADING", payload: false });
            } else {
              dispatch({
                type: "SET_INSERTED_DOCUMENT",
                payload: "Failure",
              });
              dispatch({ type: "SET_LOADING", payload: false });
            }
          });
      });
  };
};

export const getPublicDocuments = () => {
  //console.log("ACTION | getPublicDocuments");
  return (dispatch) => {
    apiGetPublicDocuments()
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          //console.log("ACTION | GetPublicDocuments ERROR:", error);
          return { undefined };
        }
      })
      .then((responseDoc) => {
        if (responseDoc) {
          dispatch({
            type: "SET_PUBLIC_DOCUMENTS",
            payload: responseDoc,
          });
        } else {
          dispatch({
            type: "SET_PUBLIC_DOCUMENTS",
            payload: [],
          });
        }
      });
  };
};
