import Collapse from "react-bootstrap/Collapse";
import { Button } from "design-react-kit";
import React, { useState } from "react";

export const AccordionConsip = ({ data }) => {
  const [active, setActive] = useState(-1);
  const changeActive = (id) => {
    if (active !== id) setActive(id);
    else setActive(-1);
  };

  return (
    <div>
      {data?.length > 0 &&
        data.map((elem, index) => (
          <div key={"accordion" + index}>
            <Button
              onFocus={() => {}}
              className="text-left"
              color="secondary"
              style={
                active === elem?.id
                  ? { boxShadow: "none" }
                  : { boxShadow: "none", border: "1px solid #e6e9f2" }
              }
              outline={active !== elem?.id}
              block
              aria-controls={elem?.id}
              aria-expanded={active === elem?.id}
              onClick={() => changeActive(elem?.id)}
              tag="button"
            >
              {elem?.title}
            </Button>
            <Collapse in={active === elem?.id}>
              <div className="px-4" id={elem?.id}>
                <p>{elem?.bodyStart}</p>
                {elem?.bodyBulletPoint?.length > 0 && (
                  <ul>
                    {elem.bodyBulletPoint.map((text, index) => (
                      <li key={"li" + index}>{text}</li>
                    ))}
                  </ul>
                )}
                <p>{elem?.bodyMiddle}</p>
                {elem?.bodyBulletPoint2?.length > 0 && (
                  <ul>
                    {elem.bodyBulletPoint2.map((text, index) => (
                      <li key={"li" + index}>{text}</li>
                    ))}
                  </ul>
                )}
                <p>{elem?.bodyEnd}</p>
                {elem?.links?.length > 0 &&
                  elem.links.map((value, index) => (
                    <p key={index}>
                      {value?.textLink}
                      <a
                        href={value?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {value?.nameLink !== "" && value?.nameLink !== undefined
                          ? value.nameLink
                          : value.link}
                      </a>
                    </p>
                  ))}
              </div>
            </Collapse>
          </div>
        ))}
    </div>
  );
};
